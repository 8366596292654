.app-container {
  display: flex;
  height: 100vh;
  font-family: 'Arial', sans-serif;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.sidebar h2 {
  font-size: 22px;
  margin-bottom: 20px;
}

.sidebar-nav {
  flex-grow: 1;
}

.sidebar-nav a {
  display: block;
  padding: 10px 0;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s;
}

.sidebar-nav a:hover,
.sidebar-nav a.active {
  background-color: #34495e;
  border-radius: 5px;
}

.logout-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c0392b;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ecf0f1;
  overflow-y: auto;
}
