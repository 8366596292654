/* Contenedor principal que centra el login en la pantalla */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ecf0f1, #bdc3c7);
}

/* Tarjeta de login con fondo blanco y sombra */
.login-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  width: 320px;
  text-align: center;
}

/* Título de la tarjeta */
.login-card h2 {
  margin-bottom: 20px;
  color: #2c3e50;
}

/* Contenedor del formulario, para alinear inputs y botón */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra inputs y botón horizontalmente */
}

/* Inputs y botón con mismo ancho y estilo */
.login-form input,
.login-form button {
  width: 100%;
  max-width: 280px; /* Ajusta a tu preferencia */
  margin: 10px 0;
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #bdc3c7;
  box-sizing: border-box; /* Evita que padding aumente el ancho total */
}

/* Efecto focus en los inputs */
.login-form input:focus {
  outline: none;
  border-color: #3498db;
}

/* Estilos específicos para el botón */
.login-form button {
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover del botón */
.login-form button:hover {
  background-color: #2980b9;
}

/* Mensaje de error */
.error-message {
  color: #e74c3c;
  margin-top: 15px;
  font-size: 14px;
}
