.resumen-fluidez .filtros-resumen {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem;
  }
  
  .resumen-fluidez .filtro {
    display: flex;
    flex-direction: column;
  }
  
  .resumen-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .resumen-table th,
  .resumen-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  
  .resumen-table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .chart-container {
    max-width: 400px;
    margin: 1rem auto;
  }
  .categorias-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .categoria-column {
    flex: 1;
    margin: 0 0.5rem;
  }
  .categoria-column h6 {
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .categoria-column ul {
    list-style: none;
    padding: 0;
  }
  .categorias-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  .categorias-table th,
  .categorias-table td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    vertical-align: top;
  }
  .categorias-table th {
    background-color: #f2f2f2;
    text-align: center;
  }
  .categorias-table ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  .chart-row {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 20px;
  }
  .chart-container {
    flex: 1;
    min-width: 300px;
  }
  