/* Atrasos.css */
.atrasos-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #333;
  }
  
  h1, h2 {
    text-align: center;
    color: #34495e;
  }
  
  .filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filter-item {
    flex: 1 1 30%;
    margin: 10px;
  }
  
  .filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .filter-item input,
  .filter-item select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .table-container {
    overflow-x: auto;
    margin: 20px 0;
  }
  
  .atrasos-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .atrasos-table th,
  .atrasos-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .atrasos-table th {
    background-color: #2c3e50;
    color: #fff;
  }
  
  .atrasos-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .atrasos-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .chart-container {
    margin: 30px auto;
    width: 100%;
  }
  
  .export-btn-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .export-btn {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .export-btn:hover {
    background-color: #218838;
  }
  .chart-container canvas {
    max-width: 100% !important;
  }
  /* Puedes agregar este CSS adicional a Atrasos.css para los contenedores de sección */
.section-container {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}
/* Atrasos.css */
/* ... (tu CSS existente para la tabla, filtros, etc.) ... */

/* Acordeón para la página de atrasos */
.atrasos-accordion {
  margin-bottom: 20px;
}

.atrasos-accordion-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}

.atrasos-accordion-header {
  background-color: #f7f7f7;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.atrasos-accordion-header h2 {
  margin: 0;
  font-size: 18px;
  color: #34495e;
}

.atrasos-accordion-icon {
  font-size: 24px;
  font-weight: bold;
  color: #34495e;
}

.atrasos-accordion-content {
  padding: 10px 15px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}
