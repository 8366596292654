.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dashboard-content {
  margin-left: 250px;
  padding: 20px;
  min-height: 100vh;
  background-color: #ecf0f1;
}
.app-layout {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
}

.content {
  flex-grow: 1;
  margin-left: 250px; /* Para que el contenido no quede tapado por el sidebar */
  padding: 20px;
  overflow-y: auto;
  background-color: #ecf0f1;
}

.app-container {
  display: flex;
}

.main-content {
  margin-left: 260px; /* Mayor margen para despegar del Sidebar */
  padding: 40px;      /* Más espacio interno */
  box-sizing: border-box;
  width: calc(100% - 260px); /* Ajusta el ancho para evitar que quede debajo del Sidebar */
  overflow-y: auto;
}



body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f7f9;
  margin: 0;
  padding: 0;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}

