/* ============================= */
/* ESTILOS GENERALES (COMÚNES)    */
/* ============================= */
.page-content {
  margin-left: 250px;
  padding: 20px;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.page-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2c3e50;
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.button:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.create-button {
  background-color: #1abc9c;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-button:hover {
  background-color: #16a085;
  transform: scale(1.05);
}

.delete-button {
  background-color: #e74c3c;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-left: 10px;
}

.delete-button:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

/* ============================= */
/* CONTENEDORES Y FLEXBOX        */
/* ============================= */
.flex-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.flex-container > div {
  flex: 1;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.flex-container > div:hover {
  background-color: #dcdde1;
  transform: translateY(-3px);
}

/* ============================= */
/* CONTENEDOR DE GRÁFICOS        */
/* ============================= */
.chart-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: 20px auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ============================= */
/* TABLAS GENERALES              */
/* ============================= */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.table th {
  background-color: #2c3e50;
  color: white;
  cursor: pointer;
}

.table tr:hover td {
  background-color: #ecf0f1;
}

.table tfoot tr {
  font-weight: bold;
  background-color: #f5f5f5;
}

/* ============================= */
/* TABLA DE MATRÍCULAS          */
/* ============================= */
.students-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.students-table th,
.students-table td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
  transition: background-color 0.3s ease;
  font-size: 0.75em;
}

.students-table th {
  background-color: #f5f5f5;
}

.students-table tr:hover td {
  background-color: #ecf0f1;
}

.students-table th:last-child,
.students-table td:last-child {
  font-weight: bold;
  background-color: #d1ecf1;
  color: #0c5460;
}

.students-table tfoot tr {
  font-weight: bold;
  background-color: #f5f5f5;
}


/* ============================= */
/* TABLAS DE RANKING (Top/Bottom) */
/* ============================= */
.top-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.top-table th,
.top-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.top-table th {
  background-color: #34495e;
  color: #fff;
  cursor: pointer;
}

.top-table tr:hover td {
  background-color: #ecf0f1;
}

/* ============================= */
/* INPUTS Y SELECTS              */
/* ============================= */
input[type="text"],
select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
select:focus {
  border-color: #3498db;
  outline: none;
}

/* ============================= */
/* EXTRAS (Spinner, etc.)        */
/* ============================= */
.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Fondo semitransparente */
  backdrop-filter: blur(5px); /* Efecto de desenfoque */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


/* ============================= */
/* ESTILOS ESPECÍFICOS PARA DASHBOARD */
/* (Se aplican solo si usas las clases exclusivas en Dashboard.js) */
/* ============================= */
.dashboard-container {
  /* Solo se aplican en Dashboard.js si envuelves el contenido en este contenedor */
}

.charts-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  margin-bottom: 20px;
}

.charts-row .chart-container {
  flex: 1;
  min-width: 300px;
  height: 600px;
  margin: 0;
}
/* ============================= */
/* ESTILOS ESPECÍFICOS PARA MATRICULAS */
/* ============================= */
.matriculas-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Título Principal */
.matriculas-container h1 {
  font-size: 32px;
  color: #34495e;
  text-align: center;
  margin-bottom: 30px;
}

/* Filtros */
.matriculas-container .filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 30px;
}

.matriculas-container .filter {
  display: flex;
  flex-direction: column;
}

.matriculas-container .filter label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #2c3e50;
}

.matriculas-container .filter select,
.matriculas-container .filter input[type="text"] {
  padding: 8px 10px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.matriculas-container .filter select:focus,
.matriculas-container .filter input[type="text"]:focus {
  border-color: #3498db;
}

/* Tabla de Estudiantes */
.students-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.students-table th,
.students-table td {
  padding: 10px;
  border: 1px solid #ecf0f1;
  text-align: center;
  font-size: 14px;
}

.students-table th {
  background-color: #34495e;
  color: #fff;
  cursor: pointer;
}

.students-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.students-table tfoot tr {
  font-weight: bold;
  background-color: #ecf0f1;
}

/* Contenedor de Gráficos (dual) */
.charts-dual-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
}

.chart-container {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  background: #fdfdfd;
  border: 1px solid #ecf0f1;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Ausentismo Crónico */
.ausentismo-chronica {
  margin-top: 30px;
  text-align: center;
}

.ausentismo-chronica .filter {
  margin-bottom: 15px;
  display: inline-block;
}

.ausentismo-summary-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ausentismo-summary-box {
  background: #fdfdfd;
  border: 1px solid #ecf0f1;
  border-radius: 10px;
  padding: 15px;
  margin: 0 10px;
  min-width: 250px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Rankings en Tabla */
.ranking-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.ranking-table th,
.ranking-table td {
  padding: 10px;
  border: 1px solid #ecf0f1;
  text-align: center;
  font-size: 14px;
}

.ranking-table th {
  background-color: #2c3e50;
  color: #fff;
}

.ranking-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Secciones de Ranking */
.ranking-annual,
.ranking-monthly {
  margin-top: 30px;
}

/* Botón para Exportar */
.create-button {
  background-color: #1abc9c;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-button:hover {
  background-color: #16a085;
  transform: scale(1.05);
}

/* Responsive */
@media (max-width: 768px) {
  .charts-dual-container {
    flex-direction: column;
  }
}


/* Estilos exclusivos para la tabla de Fluidez Lectora */
.fluidez-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem; /* Fuente más pequeña */
  margin-top: 1rem;
}

.fluidez-table th,
.fluidez-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
}

.fluidez-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

/* Contenedor de filtros en línea */
.filters-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.filters-container .filter {
  display: flex;
  flex-direction: column;
}

/* Botón para exportar CSV */
.export-csv-button {
  margin-top: 1rem;
}
/* Contenedor principal de la página */
.cargausuarios-page-content {
  padding: 20px;
}

.cargausuarios-page-title {
  margin-bottom: 20px;
}

/* Fila con dos tarjetas (Carga Manual y Carga CSV) */
.cargausuarios-row-of-cards {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

/* Tarjeta genérica */
.cargausuarios-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 20px;
  flex: 1;
}

/* Formulario de usuario */
.cargausuarios-user-form input,
.cargausuarios-user-form select,
.cargausuarios-user-form button {
  margin-bottom: 10px;
  display: block;
}

.cargausuarios-password-container {
  position: relative;
}

.cargausuarios-show-password-button {
  margin-left: 5px;
}

.cargausuarios-create-button {
  /* Estilos adicionales para tu botón “Crear Usuario” */
}

/* Tabla de usuarios */
.cargausuarios-user-table {
  width: 100%;
  border-collapse: collapse;
}

.cargausuarios-user-table th,
.cargausuarios-user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.cargausuarios-user-table th {
  background-color: #f4f4f4;
  cursor: pointer;
}

/* Botones de acciones */
.cargausuarios-reset-button,
.cargausuarios-delete-button {
  margin-right: 10px;
}

/* Botones de CSV */
.cargausuarios-csv-upload,
.cargausuarios-download-csv-button {
  margin-top: 10px;
  margin-right: 10px;
  display: block;
}

/* Mensajes de éxito o error */
.cargausuarios-message {
  margin: 10px 0;
  font-weight: bold;
  color: #666;
}
/* Quitar spinner en navegadores basados en Webkit (Chrome, Safari, Edge, Opera) */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Quitar spinner en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
